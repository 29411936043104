@import '~antd/dist/antd.less';
@import '~antd/lib/style/themes/default.less';
@import 'att-calendar.less';

@primary-color: #ad9259;
@hover-color: #f9f9fc;

body {
  overscroll-behavior-y: contain !important;
}

.logo {
  height: 80px;
  margin: 15px 55px;
}

.logo-icon {
  height: 35px;
  margin: 20px 2px;
}

.admin-header {
  padding-right: 0;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff !important;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.15);
  z-index: 10;

  .rightContainer {
    display: flex;
    align-items: center;
  }

  .sider-trigger {
    font-size: 18px;
    line-height: 64px;
    transition: color 0.3s;
  }

  .sider-trigger:hover {
    color: #1890ff;
  }

  @media (max-width: 767px) {
    .header-top {
      width: 100% !important;
    }
  }
}

.notification-button {
  cursor: pointer;

  &:hover {
    .notification-icon {
      color: @primary-color;
    }
  }

  .notification-icon {
    color: #b2b0c7;
    font-size: 24px;
  }
}

.notification {
  padding: 24px 0;
  width: 320px;

  .notificationItem {
    transition: all 0.3s;
    padding: 12px 24px;
    cursor: pointer;

    &:hover {
      background-color: @hover-color;
    }
  }

  .clearButton {
    text-align: center;
    height: 48px;
    line-height: 48px;
    cursor: pointer;
  }
}

.body-container {
  margin: 20px 16px;
  padding: 24px;
  min-height: 280px;
  background: #fff;
}

.ant-layout-sider {
  height: 100vh !important;
}

.ant-page-header-back-button {
  cursor: initial !important;
}

.ant-menu-inline-collapsed {
  width: 60px !important;
}

.ant-menu-item {
  padding: 0 22px !important;
}

.avatar-uploader > .ant-upload {
  width: 256px;
  height: 256px;
}

// GRID
.ant-modal-tabs-only {
  .ant-modal-body {
    padding-top: 8px !important;
  }
}

.ant-descriptions-item-label {
  font-weight: 500 !important;
}

.advanced-search-form,
.advanced-search-form {
  padding: 24px;
  background: #ffffff;
  margin: 24px 16px 0 16px;
}

.advanced-search-form .ant-form-item,
.advanced-search-form .ant-form-item {
  display: flex;
}

.advanced-search-form .ant-form-item-control-wrapper,
.advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}


/*
LOGIN PAGE ---------------------------------------------------------------------------
*/

.login-page {
  padding: 40px 25px 100px;
  margin: auto;
  max-width: 400px;


  h2 {
    font-size: 2em;
    font-weight: bold;
    text-align: center;
  }
}

.login-form {
  max-width: 300px;
}

.login-form-button {
  width: 100%;
}

.info-version {
  text-align: center;
  padding: 14px;
  font-size: 12px;
  color: #6c7a90;
}

.body-container-config {
  margin: 24px 16px;
  padding: 24px 0;
  min-height: 280px;
  background: #fff;
}

.report-template {
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 10px;
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #d4d4d4;
    border-radius: 10px;
  }

  /* Handle on hover */

  ::-webkit-scrollbar-thumb:hover {
    background: #a7a7a7;
  }

  .ant-list-items {
    height: 424px !important;
    overflow: auto !important;
  }

  .ant-list-header {
    padding-top: 0 !important;
    padding-right: 12px !important;
  }
}

.time-slot-container {
  justify-content: space-evenly !important;
  margin: 15px 0 0 0 !important;
}

.time-slot-column {
  p {
    background: #e1e3e8;
  }

  .time-slot-column-row {
    justify-content: space-between;
    display: flex;
    padding: 5px;
  }

  .ant-btn-dangerous {
    border: none !important;
  }

  background: #f2f3f5;

  text-align: center;
  padding: 0 !important;
  flex: 1;
  margin: 0 10px 0 10px;
}

.time-slot-column:nth-child(1) {
  margin: 0 10px 0 0;
}

.time-slot-column:nth-child(7) {
  margin: 0 0 0 10px;
}

.sigCanvas {
  background: #f1f1f1;
  margin-bottom: 30px;
  border: 2px solid #d8d8d8;
  border-radius: 4px;
}

.ant-input-number {
  width: 100% !important;
}

.cornerstone-element-wrapper,
.cornerstone-element {
  width: 512px;
  height: 512px;
  margin: 0 auto;
}

.ant-select-item-option-content {
  white-space: normal !important;
}

.attendance-buttons button {
  margin: 10px 0 15px 0;
}

.ant-picker {
  width: 100%;
}
@primary-color: #ad9259;@layout-header-background: #000000;